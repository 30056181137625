<template>
  <div class="flex-col w-full border relative pb-2 rounded-md">
    <h6
      v-if="formattedText"
      class="absolute top-0 left-0 -mt-3 text-xs text-gray-400 leading-6 ml-2-5 px-1"
      :class="[disabled ? 'bg-gray-50' : 'bg-white']"
    >
      {{ title }}
    </h6>
    <p
      class="px-2 mt-3 ml-2 py-0-5"
      :class="[disabled || !value ? 'text-gray-400' : 'text-gray-800']"
      :role="`${name}-readonly-input`"
    >
      {{ formattedText || title }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import { FUNDING_SECTION, regexPercentageRegex } from "@/helpers/constants";
import { formatDateCustom, formatMoney } from "@/helpers/formatting";
import { OFFER_SECTION } from "@/helpers/constants";
import { useI18n } from "vue-i18n";

const props = defineProps({
  value: {
    type: [String, Number],
    default: null
  },
  name: {
    type: String,
    required: true
  },
  rules: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  initialPayment: {
    type: [Number, String],
    default: 0
  },
  productTypeId: {
    type: Number,
    required: true
  },
  section: {
    type: String,
    default: OFFER_SECTION
  },
  currency: {
    type: String,
    default: "USD"
  }
});

const { getters } = useStore();
const { t } = useI18n();

const paymentPeriodOptions = computed<Record<string, string>>(() =>
  getters["options/paymentPeriods"]()
);

const formattedText = computed(() => {
  if (props.name === "total_initial_payment") {
    const prefix = "US$";
    const value = Number(props.initialPayment).toFixed(2);
    return `${prefix}${value}`;
  }

  if (props.name === "base_rate") {
    return getters["options/baseRate"][props.value];
  }

  if (props.name === "interest_charged") {
    return getters["options/interestChargedTimeframe"][props.value];
  }

  if (props.name === "term_length_timeframe") {
    return getters["options/termLengthTimeframe"][props.value];
  }

  if (props.name === "time_to_funding_timeframe") {
    return getters["options/timeToFindingTimeframe"][props.value];
  }

  if (props.rules.includes("date")) {
    return formatDateCustom(props.value as string);
  }
  if (["payment_frequency"].includes(props.name)) {
    return paymentPeriodOptions.value[props.value];
  }
  if (
    !props.name.includes("term") &&
    props.name !== "number_of_payments" &&
    props.rules.includes("numeric") &&
    (!regexPercentageRegex.test(props.name) ||
      props.name.includes("interest_amount"))
  ) {
    return formatMoney(props.value, 2, props.currency);
  }
  return props.value;
});

const title = computed(() => {
  if (
    props.section === FUNDING_SECTION &&
    ["start_date", "end_date"].includes(props.name)
  ) {
    return t(`FIELD_DICTIONARY.funding_${props.name}`);
  }
  if (props.name === "total_initial_payment") {
    return t("FUNDERS.TOTAL_INITIAL_PAYMENT_FIELD_NAME");
  }

  const options = getters["options/productFieldsDictionary"](
    props.productTypeId
  ) as Record<string, Record<string, string>>;

  const name = options[props.section][props.name];
  return name || t(`FIELD_DICTIONARY.${props.name}`);
});
</script>
